<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab
                :to="{
                    name: 'users-register',
                }"
            >
                Users
            </v-tab>
            <v-tab
                :to="{
                    name: 'users-permissions',
                }"
            >
                Permissions
            </v-tab>
            <v-tab
                :to="{
                    name: 'users-logs',
                }"
                v-if="user.permissions.includes('readUsers')"
            >
                Logs
            </v-tab>
        </v-tabs>
        <v-card>
            <v-card-text
                class="overflow-y-auto pt-0"
                :style="`height: ${height}px`"
            >
                <router-view />
            </v-card-text>
        </v-card>
        <Errors />
    </div>
</template>

<script>
import API from '@/services/api'

export default {
    name: 'usersView',
    components: {
        Errors: () => import('@/components/Layout/Errors.vue'),
    },
    data: () => ({
        height: 0,
        settings: undefined,
        errorMsg: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        user: {},
    }),
    updated() {
        this.onResize()
    },
    async mounted() {
        try {
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            const {
                data: { settings },
            } = await API.getSettings()
            this.settings = settings
        } catch (error) {
            this.errorMsg = error.message
        }
    },
    methods: {
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
            } = this.$refs
            this.height = containerHeight - tabsHeight + 18
        },
    },
}
</script>

<style></style>
